import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../container/user/pages/home/Home";
import Layout from "../container/user/pages/layout/Layout";
import StackDetail from "../components/user/pages/Home/StackDetail";

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
    
        <Route path="" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="stack-detail" element={<StackDetail />} />
        </Route>
          
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
