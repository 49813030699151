import moment from "moment";

export const validateTokenAmount = (amount, maxStakeAmount, alreadyStake) => {
  const totalStakeAmount = parseInt(amount) + parseInt(alreadyStake);

  if (!amount) {
    return "Please enter token amount";
  } else if (totalStakeAmount > maxStakeAmount) {
    return "Amount is greater than the maximum staking amount";
  } else if (amount > maxStakeAmount) {
    return "Amount is greater than the maximum staking amount";
  }
  return "";
};
  
  
  export const validateUnstakeAmount = (amount,unstakeAmountValidation,day) => {
    // const givenDate =moment(new Date('1/3/2024')).format('MM/DD/YYYY');
    const givenDate =moment(new Date(day)).format('MM/DD/YYYY');
    const currentDate = moment(new Date()).format('MM/DD/YYYY');


    if (!amount) {
      return "Please enter unstake amount";
    } else if(parseInt(amount) > parseInt(unstakeAmountValidation)){
      return "Amount is greater than the staked tokens"
        }  else if (currentDate > givenDate && parseInt(amount) !== parseInt(unstakeAmountValidation)) {
          return "Amount must be equal to staked tokens";
        }
    return "";
  };