const Api = {
  GET_PRIVACY_POLICY: "common/get-privacy-policy",
  GET_TERMS_AND_CONDITION: "common/get-terms-and-conditions",

  GET_LOCK_PERIOD: "common/lockperiod-list?list=true",

  GET_REWARD_NFT: "common/phase-detail-list?list=true",

  GET_STAKE_USER: "user/get-stake-user",
  CREATE_STAKE_DAY: "user/add-edit-stake-day",
  UPDATE_UNSTAKE: "user/update-stake-day",
};
export { Api };
