import React, { useRef, useState } from "react";
import "../../../../components/user/pages/Home/smartstaking.css";
import Index from "../../../../components/Index";
import PagesIndex from "../../../../components/PagesIndex";
import "../../../../components/user/pages/Home/smartstaking.css";
import "./stackdetail.css";
import { useAccount } from "wagmi";
import { ethers, utils } from "ethers";
import { useEthersSigner } from "../../../../Connectivity/WalletSignerprovider";
import {
  DugoutObj,
  SPNTokenObj,
} from "../../../../Connectivity/ContractObject";
import { Dugout } from "../../../../Connectivity/AddressHelper";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  validateTokenAmount,
  validateUnstakeAmount,
} from "../../../../validation/validation";
import { toast } from "react-toastify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#ff4d1d",
    color: "#ffffff",
    fontSize: "11px",
    fontFamily: "Poppins-Regular",
    padding: "5px 9px",
    lineHeight: "19px",
    borderRadius: "10px",
  },
}));

function StackDetail() {
  const [open, setOpen] = PagesIndex.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openUnstake, setOpenUstake] = PagesIndex.useState(false);
  const handleOpenUnstake = () => setOpenUstake(true);
  const handleCloseUnstake = () => setOpenUstake(false);

  const [openUnstakeModel, setOpenUstakeModel] = PagesIndex.useState(false);
  const handleOpenUnstakeModel = () => setOpenUstakeModel(true);
  const handleCloseUnstakeModel = () => setOpenUstakeModel(false);

  const signer = useEthersSigner();
  const account = useAccount();
  const [stakeDetails, setStakeDetails] = PagesIndex.useState([]);
  const [nftDetails, setNftDetails] = PagesIndex.useState([]);

  const [loading, setLoading] = PagesIndex.useState(false);
  const [listLoader, setListLoader] = PagesIndex.useState(false);
  const [unstakeAmount, setUnstakeAmount] = PagesIndex.useState("");
  const [unstakeError, setUnstakeError] = PagesIndex.useState("");
  const [fetchStakeAmount, setFetchStakeAmount] = PagesIndex.useState("");
  const { state } = PagesIndex.useLocation();

  const [unstakeModelData, setUnstakeModelData] = PagesIndex.useState("");
  const [showTable, setShowTable] = PagesIndex.useState(false);
  const [stakeAmount, setStakeAmount] = PagesIndex.useState("");
  const [stakeData, setStakeData] = PagesIndex.useState([]);
  const [validationError, setValidationError] = PagesIndex.useState("");
  const [stakingIdData, setStakingIdData] = PagesIndex.useState("");
  const [unstakeLoading, setUnstakeLoading] = PagesIndex.useState("");
  const [endStakeDate, setEndStakeDate] = PagesIndex.useState("");
  const [unstakeAmountValidation, setUnstakeAmountValidation] =
    PagesIndex.useState("");
  const [fetchAllStakeAmount, setFetchAllStakeAmount] = PagesIndex.useState(0);

  const dugoutData = DugoutObj(signer)
    .then(async (res) => {
      let daysDetails = await res.getTiersDetails(state?.day);
      setFetchAllStakeAmount(utils.formatEther(daysDetails?.stakeTokensofTier));
    })
    .catch((err) => {
      return err;
    });

  const getStakeDay = async () => {
    try {
      const res = await PagesIndex.DataService.post(
        PagesIndex.Api.GET_STAKE_USER,
        { address: account?.address }
      );
      setStakeData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  PagesIndex.useEffect(() => {
    getStakeDay();
  }, []);

  const alreadyStake = stakeData?.find((stakeItem) => {
    return stakeItem?.day == state?.day;
  });

  const createStakeDay = async (amount, numDays, id) => {
    let createStakeData;

    if (id) {
      createStakeData = {
        id: id,
        amountToken: amount,
      };
    } else {
      createStakeData = {
        day: numDays,
        address: account?.address,
        amountToken: amount,
      };
    }
    try {
      const res = await PagesIndex.DataService.post(
        PagesIndex.Api.CREATE_STAKE_DAY,
        createStakeData
      );
      setStakeAmount("");
      getStakeDay();
    } catch (error) {
      setStakeAmount("");
      setLoading(false);
    }
  };

  const StakeToken = async (amount, numDays, id) => {
    if (account?.address) {
      try {
        setLoading(true);
        const dugout = await DugoutObj(signer);
        const spntoken = await SPNTokenObj(signer);
        let stakeamount = ethers.utils.parseEther(amount.toString());
        let approve = await spntoken.approve(Dugout, stakeamount);
        await approve.wait();
        let stake = await dugout.stakeTokens(stakeamount, numDays);
        await stake.wait();
        createStakeDay(amount, numDays, id);
        toast.success("Stake Successfully");
      } catch (error) {
        setLoading(false);
        if (error?.error?.data?.message != undefined) {
          alert(error?.error?.data?.message);
        }
      }
    } else {
      connectionAlert();
    }
  };

  let [currentRows, setCurrentRows] = useState([]);

  const itemsPerPage = 10;

  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(stakeDetails?.length / itemsPerPage);

  const handleChange = (event, value) => {
    setPage(value);
  };

  PagesIndex.useEffect(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedItems = stakeDetails?.slice(startIndex, endIndex);

    setCurrentRows(displayedItems);
  }, [stakeDetails, page]);

  const fetchStakeDetails = async () => {
    try {
      setListLoader(true);
      const dugout = await DugoutObj(signer);
      const fetchdetails = await dugout.fetchStake();
      const formattedStakeDetails = fetchdetails.map((stake) => ({
        ...stake,
        tokenContracts: stake.tokensContract.toString(),
        stakeAmount: utils.formatEther(stake.stakeAmount),
        startTime: new Date(
          stake.startTime.toNumber() * 1000
        ).toLocaleDateString(),
        endTime: new Date(stake.endTime.toNumber() * 1000).toLocaleDateString(),
        early: utils.formatEther(stake.earlyFees),
        stakeddays: stake.numdays,
      }));
      const filteredStakeDetails = formattedStakeDetails?.filter((detail) => {
        return detail?.stakeddays.toNumber() == state?.day;
      });

      setStakeDetails(filteredStakeDetails);

      setListLoader(false);
    } catch (error) {
      setListLoader(false);
      if (error?.error?.data?.message != undefined) {
        alert(error?.error?.data?.message);
      }
    }
  };

  const fetchNFTDetails = async () => {
    try {
      const dugout = await DugoutObj(signer);
      let nftdetails = await dugout.fetchNFTDetails();
      const formattednftDetails = nftdetails.map((nft) => ({
        ...nft,
        rewardType: nft.rewardNFTType,
        rewardTime: new Date(
          nft.timeofRewardNFT.toNumber() * 1000
        ).toLocaleDateString(),
        ownerAddress: nft.nftOwner.toString(),
        nftAddress: nft.nftContractAddress.toString(),
      }));

      setNftDetails(formattednftDetails);
    } catch (error) {}
  };

  PagesIndex.useEffect(() => {
    fetchStakeDetails();
    fetchNFTDetails();
  }, [showTable, stakeDetails?.length]);

  const updateUnstake = async (isConfirmd) => {
    const unStakeData = {
      day: parseInt(state?.day),
      address: account?.address,
      amountToken: parseInt(unstakeAmount),
      maximumStakingAmount: state?.maximumStakingAmount,
      EarlyUnstakeFees: state?.EarlyUnstakeFees,
      apyrate: state?.apyrate,
      isConfirmd: isConfirmd ? isConfirmd : "",
    };
    setUnstakeLoading(true);
    try {
      const res = await PagesIndex.DataService.post(
        PagesIndex.Api.UPDATE_UNSTAKE,
        unStakeData
      );
      getStakeDay();
      setLoading(false);
      fetchStakeDetails();
      setUnstakeModelData(res.data);
      setShowTable(false);
      if (isConfirmd) {
        handleOpenUnstake();
        setUnstakeAmount("");
      }
      setUnstakeLoading(false);
    } catch (error) {
      setUnstakeLoading(false);
      setUnstakeAmount("");
      setLoading(false);
    }
  };

  const Unstake = async (_positionId, _amount, isConfirmd) => {
    if (account?.address) {
      try {
        setLoading(true);
        const dugout = await DugoutObj(signer);
        let stakeamount = ethers.utils.parseEther(_amount.toString());
        console.log(dugout, "252");
        let unstake = await dugout.Unstake(_positionId, stakeamount);
        console.log(dugout, "141");
        console.log(unstake, "1412");
        await unstake.wait();
        updateUnstake(isConfirmd);
        toast.success("Unstake Successfully");
      } catch (error) {
        setLoading(false);
        if (error?.error?.data?.message != undefined) {
          alert(error?.error?.data?.message);
        }
      }
    } else {
      connectionAlert();
    }
  };
  const connectionAlert = () => {
    alert("Please connect Metamask");
  };

  const tableRef = useRef();

  const scrollToTable = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  PagesIndex.useEffect(() => {
    scrollToTable();
  }, [showTable]);

  return (
    <>
      <Index.Box className="home-container-stake-main ">
        <Index.Box className="smart-staking-wrapper home-container">
          {loading ? (
            <Index.loader />
          ) : (
            <>
              {state && (
                <>
                  {" "}
                  <Index.Box className="spn-token-wrapper">
                    <Index.Box className="spn-btn-main">
                      <Index.Box className="spn-days">
                        <Index.Box
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <img
                            src={PagesIndex.Png.spnLogo}
                            alt="stakeSNP"
                            className="stake-spn-img"
                          />
                          <Index.Typography
                            component="p"
                            className="stake-spn-text"
                          >
                            {state?.stakeSpn}
                          </Index.Typography>
                        </Index.Box>
                        <Index.Button className="days-btn">
                          {state?.day} days
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="staking-btn-sec">
                        <Index.BorderBgButton
                          btnLabel="Staking"
                          className="br-bg-btn"
                          onClick={() => {
                            const validationError = validateTokenAmount(
                              stakeAmount,
                              state?.maximumStakingAmount,
                              fetchAllStakeAmount
                            );
                            if (validationError) {
                              setValidationError(validationError);
                            } else {
                              const id = stakeData?.find(
                                (stakeItem) => stakeItem.day == state?.day
                              );

                              StakeToken(stakeAmount, state?.day, id?._id);
                            }
                          }}
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="api-rate-main">
                      <Index.Box className="api-rate-amount">
                        <Index.Typography
                          component="p"
                          className="api-rate-text"
                        >
                          Lock period : <span>{state?.day} days</span>{" "}
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          className="api-rate-text"
                        >
                          Early unstake fee :{" "}
                          <span>{state?.EarlyUnstakeFees}%</span>{" "}
                        </Index.Typography>

                        <Index.Typography
                          component="p"
                          className="api-rate-text"
                        >
                          Maximum Staking Amount :{" "}
                          <span>{state?.maximumStakingAmount} SPN</span>{" "}
                        </Index.Typography>

                        <Index.Typography
                          component="p"
                          className="api-rate-text"
                        >
                          Already staked Amount :{" "}
                          <span>
                            {fetchAllStakeAmount ? fetchAllStakeAmount : 0}{" "}
                          </span>{" "}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="api-rate-value">
                        <Index.Typography
                          component="p"
                          className="stake-spn-text"
                        >
                          APY Rate
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          className="api-rate-percent"
                        >
                          {state?.apyrate}%
                        </Index.Typography>
                        <Index.Box className="stake-input-field">
                          <Index.Typography
                            component="p"
                            className="stake-spn-text stake-token-amount"
                          >
                            Token Amount :
                          </Index.Typography>
                          <Index.TextField
                            component="p"
                            className="api-rate-input"
                            placeholder="Enter Amount Token"
                            type="string"
                            value={stakeAmount}
                            onChange={(e) => {
                              const inputValue = e.target.value.replace(
                                /\D/g,
                                ""
                              );
                              if (inputValue !== "0") {
                                setStakeAmount(inputValue.slice(0, 12));
                                setValidationError(
                                  validateTokenAmount(
                                    inputValue,
                                    state?.maximumStakingAmount,
                                    fetchAllStakeAmount
                                  )
                                );
                              }
                            }}
                          />
                          {validationError && (
                            <Index.Typography className="error-message">
                              {validationError}
                            </Index.Typography>
                          )}
                        </Index.Box>

                        {alreadyStake?.amountToken > 0 && (
                          <Index.Box className="staking-btn-sec border-bg-btn-user">
                            <Index.BorderBgButton
                              onClick={() => {
                                showTable === true
                                  ? setShowTable(false)
                                  : setShowTable(true);
                              }}
                              btnLabel="User Stake Details"
                              className="br-bg-btn"
                            />
                          </Index.Box>
                        )}
                      </Index.Box>
                    </Index.Box>
                    <Index.Typography component="p" className="what-is-title">
                      {state?.title}
                    </Index.Typography>
                    <Index.Typography component="p" className="what-is-ans">
                      {state?.description &&
                        new DOMParser().parseFromString(
                          state.description.replace(/&nbsp;/g, ""),
                          "text/html"
                        ).body.textContent}
                    </Index.Typography>
                    {showTable && (
                      <Index.Box className="main-dashboard">
                        <Index.Box className="main-title"></Index.Box>
                        {listLoader ? (
                          <Index.loader />
                        ) : (
                          <Index.Box
                            ref={tableRef}
                            className="page-content-box"
                          >
                            <Index.Box className="barge-common-box">
                              <Index.Box className="sahay-table cus-table-wrapper">
                                <Index.TableContainer
                                  component={Index.Paper}
                                  className="table-container"
                                >
                                  <Index.Table
                                    aria-label="simple table"
                                    className="table-design-main barge-table"
                                  >
                                    <Index.TableHead>
                                      <Index.TableRow>
                                        <Index.TableCell align="left">
                                          No.
                                        </Index.TableCell>

                                        <Index.TableCell align="left">
                                          Position Id
                                        </Index.TableCell>
                                        <Index.TableCell align="left">
                                          Token Amount
                                        </Index.TableCell>
                                        <Index.TableCell align="left">
                                          Start Date
                                        </Index.TableCell>
                                        <Index.TableCell align="left">
                                          End Date
                                        </Index.TableCell>
                                        <Index.TableCell align="left">
                                          Early Fees
                                        </Index.TableCell>
                                        <Index.TableCell align="left">
                                          Token Address
                                        </Index.TableCell>

                                        <Index.TableCell align="left">
                                          Action
                                        </Index.TableCell>
                                      </Index.TableRow>
                                    </Index.TableHead>
                                    {loading ? (
                                      <Index.TableBody className="table-body">
                                        <Index.TableRow
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <Index.TableCell
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                            colSpan={6}
                                          >
                                            <Index.loader />
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      </Index.TableBody>
                                    ) : (
                                      <Index.TableBody>
                                        {currentRows?.length > 0
                                          ? currentRows?.map(
                                              (item, index, row) => (
                                                <Index.TableRow
                                                  key={item.item?.stakingId.toString()}
                                                >
                                                  <Index.TableCell align="left">
                                                    {(page - 1) * itemsPerPage +
                                                      index +
                                                      1}
                                                  </Index.TableCell>

                                                  <Index.TableCell align="left">
                                                    {item?.stakingId.toString()}
                                                  </Index.TableCell>
                                                  <Index.TableCell align="left">
                                                    {item?.stakeAmount}
                                                  </Index.TableCell>
                                                  <Index.TableCell align="left">
                                                    {item?.startTime}
                                                  </Index.TableCell>
                                                  <Index.TableCell align="left">
                                                    {item?.endTime}
                                                  </Index.TableCell>
                                                  <Index.TableCell align="left">
                                                    {item?.early}
                                                  </Index.TableCell>
                                                  <Index.TableCell align="left">
                                                    <LightTooltip
                                                      title={
                                                        item?.tokenContracts
                                                      }
                                                    >
                                                      {item?.tokenContracts?.slice(
                                                        0,
                                                        15
                                                      ) + "..."}
                                                    </LightTooltip>
                                                  </Index.TableCell>

                                                  <Index.TableCell align="left">
                                                    <Index.Box className="gray-btn-main">
                                                      <Index.Button
                                                        className="modal-delete-btn modal-btn"
                                                        onClick={() => {
                                                          <Index.TableBody>
                                                            {currentRows?.length >
                                                            0
                                                              ? currentRows?.map(
                                                                  (
                                                                    item,
                                                                    index,
                                                                    row
                                                                  ) => (
                                                                    <Index.TableRow
                                                                      key={item.item?.stakingId.toString()}
                                                                    >
                                                                      <Index.TableCell align="left">
                                                                        {(page -
                                                                          1) *
                                                                          itemsPerPage +
                                                                          index +
                                                                          1}
                                                                      </Index.TableCell>

                                                                      <Index.TableCell align="left">
                                                                        {item?.stakingId.toString()}
                                                                      </Index.TableCell>
                                                                      <Index.TableCell align="left">
                                                                        {
                                                                          item?.stakeAmount
                                                                        }
                                                                      </Index.TableCell>
                                                                      <Index.TableCell align="left">
                                                                        {
                                                                          item?.startTime
                                                                        }
                                                                      </Index.TableCell>
                                                                      <Index.TableCell align="left">
                                                                        {
                                                                          item?.endTime
                                                                        }
                                                                      </Index.TableCell>

                                                                      <Index.TableCell align="left">
                                                                        {
                                                                          item?.early
                                                                        }
                                                                      </Index.TableCell>
                                                                      <Index.TableCell align="left">
                                                                        <LightTooltip
                                                                          title={
                                                                            item?.tokenContracts
                                                                          }
                                                                        >
                                                                          {item?.tokenContracts?.slice(
                                                                            0,
                                                                            15
                                                                          ) +
                                                                            "..."}
                                                                        </LightTooltip>
                                                                      </Index.TableCell>

                                                                      <Index.TableCell align="left">
                                                                        <Index.Box className="gray-btn-main">
                                                                          <Index.Button
                                                                            className="modal-delete-btn modal-btn"
                                                                            onClick={() => {
                                                                              setStakingIdData(
                                                                                item?.stakingId
                                                                              );
                                                                              setFetchStakeAmount(
                                                                                item?.stakeAmount
                                                                              );
                                                                              setUnstakeAmountValidation(
                                                                                item?.stakeAmount
                                                                              );
                                                                              setEndStakeDate(
                                                                                item?.endTime
                                                                              );
                                                                              handleOpen();
                                                                            }}
                                                                          >
                                                                            Unstake
                                                                          </Index.Button>
                                                                        </Index.Box>
                                                                      </Index.TableCell>
                                                                    </Index.TableRow>
                                                                  )
                                                                )
                                                              : ""}
                                                          </Index.TableBody>;
                                                          setStakingIdData(
                                                            item?.stakingId
                                                          );
                                                          setFetchStakeAmount(
                                                            item?.stakeAmount
                                                          );
                                                          setUnstakeAmountValidation(
                                                            item?.stakeAmount
                                                          );
                                                          setEndStakeDate(
                                                            item?.endTime
                                                          );
                                                          handleOpen();
                                                        }}
                                                      >
                                                        Unstake
                                                      </Index.Button>
                                                    </Index.Box>
                                                  </Index.TableCell>
                                                </Index.TableRow>
                                              )
                                            )
                                          : ""}
                                      </Index.TableBody>
                                    )}
                                  </Index.Table>
                                </Index.TableContainer>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="pagination-design flex-start">
                              <Index.Stack spacing={2}>
                                <Index.Pagination
                                  count={totalPages}
                                  page={page}
                                  onChange={handleChange}
                                  variant="outlined"
                                  shape="rounded"
                                />
                              </Index.Stack>
                            </Index.Box>
                          </Index.Box>
                        )}
                      </Index.Box>
                    )}
                  </Index.Box>{" "}
                </>
              )}
            </>
          )}

          <Index.Modal
            open={open}
            // onClose={() => {
            //   handleClose();
            //   setUnstakeError("");
            // }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-delete modal"
          >
            <Index.Box
              sx={style}
              className="delete-modal-inner-main modal-inner"
            >
              <Index.Typography
                className="delete-modal-para common-para"
                component="p"
                variant="p"
              >
                Do you really want to Unstake ?
              </Index.Typography>
              <Index.Box className="stake-input-field user-modal-set">
                <Index.TextField
                  component="p"
                  className="api-rate-input"
                  placeholder="Enter Amount Token"
                  value={unstakeAmount}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/\D/g, "");
                    if (inputValue !== "0") {
                      setUnstakeAmount(inputValue.slice(0, 12));
                      setUnstakeError(
                        validateUnstakeAmount(
                          inputValue,
                          unstakeAmountValidation,
                          endStakeDate
                        )
                      );
                    }
                  }}
                ></Index.TextField>
                {unstakeError && (
                  <Index.Typography className="error-message">
                    {unstakeError}
                  </Index.Typography>
                )}
              </Index.Box>

              <Index.Box className="delete-modal-btn-flex">
                <Index.Button
                  className="modal-cancel-btn modal-btn"
                  onClick={() => {
                    handleClose();
                    setUnstakeAmount("");
                    setUnstakeError("");
                  }}
                >
                  Cancel
                </Index.Button>

                <Index.Button
                  className="modal-delete-btn modal-btn"
                  onClick={() => {
                    const validationError = validateUnstakeAmount(
                      unstakeAmount,
                      unstakeAmountValidation,
                      endStakeDate
                    );
                    if (validationError) {
                      setUnstakeError(validationError);
                    } else {
                      updateUnstake();
                      handleOpenUnstakeModel();
                      handleClose();
                    }
                  }}
                >
                  Unstake
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Modal>

          <Index.Modal
            open={openUnstake}
            onClose={() => {
              handleCloseUnstake();
              setUnstakeModelData("");
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-unstake"
          >
            {unstakeLoading ? (
              <Index.Box className="model-unstake-loader">
                <Index.loader />
              </Index.Box>
            ) : (
              <Index.Box className="unstake-model-main-box">
                <Index.Box className="unstake-heading-box">
                  <Index.Typography className="heading">
                    Unstake Details
                  </Index.Typography>

                  <Index.IconButton
                    onClick={() => {
                      handleCloseUnstake();
                      setUnstakeModelData("");
                    }}
                    className="unstake-head-icon"
                  >
                    <img src={PagesIndex.Svg.closeIcon} />
                  </Index.IconButton>
                </Index.Box>
                <Index.Box className="divider-box">
                  <Index.Divider className="divider" />
                </Index.Box>

                <Index.Box className="unstake-lock-period-box">
                  <Index.Typography className="unstake-lock-period-text">
                    Stake Tokens{" "}
                  </Index.Typography>
                  <Index.Typography className="unstake-lock-period-day">
                    {" "}
                    {fetchStakeAmount && fetchStakeAmount}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="unstake-lock-period-box">
                  <Index.Typography className="unstake-lock-period-text">
                    Unstake Requested Tokens{" "}
                  </Index.Typography>
                  <Index.Typography className="unstake-lock-period-day">
                    {" "}
                    {unstakeModelData?.data?.amountToken}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="unstake-lock-period-box">
                  <Index.Typography className="unstake-lock-period-text">
                    Lock Period{" "}
                  </Index.Typography>
                  <Index.Typography className="unstake-lock-period-day">
                    {unstakeModelData?.data?.day
                      ? unstakeModelData?.data?.day
                      : 0}{" "}
                    days
                  </Index.Typography>
                </Index.Box>

                {unstakeModelData?.data?.EarlyUnstakeFees && (
                  <Index.Box className="unstake-lock-period-box">
                    <Index.Typography className="unstake-lock-period-text">
                      Early Unstake Fee
                    </Index.Typography>
                    <Index.Typography className="unstake-lock-period-day">
                      {" "}
                      {unstakeModelData?.data?.EarlyUnstakeFees}%
                    </Index.Typography>
                  </Index.Box>
                )}

                {unstakeModelData?.data?.stakeamount && (
                  <Index.Box className="unstake-lock-period-box">
                    <Index.Typography className="unstake-lock-period-text">
                      Early Unstake Fee(tokens){" "}
                    </Index.Typography>
                    <Index.Typography className="unstake-lock-period-day">
                      {unstakeModelData?.data?.stakeamount}
                    </Index.Typography>
                  </Index.Box>
                )}

                {unstakeModelData?.data?.totalDaysEarlyStake && (
                  <Index.Box className="unstake-lock-period-box">
                    <Index.Typography className="unstake-lock-period-text">
                      Early Unstake Fee(token)
                    </Index.Typography>
                    <Index.Typography className="unstake-lock-period-day">
                      {(unstakeModelData?.data?.totalDaysEarlyStake).toFixed(2)}
                    </Index.Typography>
                  </Index.Box>
                )}

                {unstakeModelData?.Reward?.[0]?.rewardtype && (
                  <>
                    <Index.Box className="unstake-lock-period-box">
                      <Index.Typography className="unstake-lock-period-text">
                        Reward NFT{" "}
                      </Index.Typography>
                      <Index.Typography className="unstake-lock-period-day">
                        {" "}
                        {unstakeModelData?.Reward?.[0]?.rewardtype}{" "}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="unstake-lock-period-box">
                      <Index.Typography className="unstake-lock-period-text">
                        Reward Token Id
                      </Index.Typography>
                      <Index.Typography className="unstake-lock-period-day">
                        {" "}
                        {nftDetails[0]?.tokenId.toNumber()}{" "}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="unstake-lock-period-box">
                      <Index.Typography className="unstake-lock-period-text">
                        Reward Address
                      </Index.Typography>
                      <Index.Typography className=" reward-address-pop-up">
                        {" "}
                        {nftDetails[0]?.nftContractAddress.toString()}{" "}
                      </Index.Typography>
                    </Index.Box>
                  </>
                )}

                {unstakeModelData && (
                  <Index.Box className="unstake-lock-period-box">
                    <Index.Typography className="unstake-lock-period-text">
                      Reward Tokens
                    </Index.Typography>
                    <Index.Typography className="unstake-lock-period-day">
                      {unstakeModelData?.rewardToken
                        ? unstakeModelData?.rewardToken.toFixed(2)
                        : unstakeModelData?.data?.rewardTokenRemining
                        ? unstakeModelData?.data?.rewardTokenRemining.toFixed(2)
                        : 0}{" "}
                    </Index.Typography>
                  </Index.Box>
                )}

                {unstakeModelData && (
                  <Index.Box className="unstake-lock-period-box">
                    <Index.Typography className="unstake-lock-period-text">
                      Final Tokens Received
                    </Index.Typography>
                    <Index.Typography className="unstake-lock-period-day">
                      {unstakeModelData?.finalToken
                        ? unstakeModelData?.finalToken.toFixed(2)
                        : unstakeModelData?.data?.finalToken
                        ? unstakeModelData?.data?.finalToken.toFixed(2)
                        : 0}{" "}
                    </Index.Typography>
                  </Index.Box>
                )}
              </Index.Box>
            )}
          </Index.Modal>

          <Index.Modal
            open={openUnstakeModel}
            // onClose={() => {
            //   handleCloseUnstakeModel();
            //   setUnstakeModelData("");
            // }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal-unstake"
          >
            {unstakeLoading ? (
              <Index.Box className="model-unstake-loader">
                <Index.loader />
              </Index.Box>
            ) : (
              <Index.Box className="unstake-model-main-box">
                <Index.Box className="unstake-heading-box">
                  <Index.Typography className="heading">
                    Unstake Details
                  </Index.Typography>

                  <Index.IconButton
                    onClick={() => {
                      handleCloseUnstakeModel();
                      setUnstakeModelData("");
                      setUnstakeAmount("");
                    }}
                    className="unstake-head-icon"
                  >
                    <img src={PagesIndex.Svg.closeIcon} />
                  </Index.IconButton>
                </Index.Box>
                <Index.Box className="divider-box">
                  <Index.Divider className="divider" />
                </Index.Box>
                {unstakeModelData?.data && (
                  <Index.Box className="unstake-lock-period-box">
                    <Index.Typography className="unstake-lock-period-text">
                      Stake Tokens{" "}
                    </Index.Typography>
                    <Index.Typography className="unstake-lock-period-day">
                      {" "}
                      {fetchStakeAmount && fetchStakeAmount}
                    </Index.Typography>
                  </Index.Box>
                )}
                {unstakeModelData?.data?.amountToken && (
                  <Index.Box className="unstake-lock-period-box">
                    <Index.Typography className="unstake-lock-period-text">
                      Unstake Requested Tokens{" "}
                    </Index.Typography>
                    <Index.Typography className="unstake-lock-period-day">
                      {" "}
                      {unstakeModelData?.data?.amountToken}
                    </Index.Typography>
                  </Index.Box>
                )}

                <Index.Box className="unstake-lock-period-box">
                  <Index.Typography className="unstake-lock-period-text">
                    Lock Period{" "}
                  </Index.Typography>
                  <Index.Typography className="unstake-lock-period-day">
                    {unstakeModelData?.data?.day
                      ? unstakeModelData?.data?.day
                      : 0}{" "}
                    days
                  </Index.Typography>
                </Index.Box>

                {unstakeModelData?.Reward?.[0]?.rewardtype && (
                  <>
                    <Index.Box className="unstake-lock-period-box">
                      <Index.Typography className="unstake-lock-period-text">
                        Reward NFT{" "}
                      </Index.Typography>
                      <Index.Typography className="unstake-lock-period-day">
                        {" "}
                        {unstakeModelData?.Reward?.[0]?.rewardtype}{" "}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="unstake-lock-period-box">
                      <Index.Typography className="unstake-lock-period-text">
                        Reward Token Id
                      </Index.Typography>
                      <Index.Typography className="unstake-lock-period-day">
                        {" "}
                        {nftDetails[0]?.tokenId.toNumber()}{" "}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="unstake-lock-period-box">
                      <Index.Typography className="unstake-lock-period-text">
                        Reward Address
                      </Index.Typography>
                      <Index.Typography className=" reward-address-pop-up">
                        {" "}
                        {nftDetails[0]?.nftContractAddress.toString()}{" "}
                      </Index.Typography>
                    </Index.Box>
                  </>
                )}

                {unstakeModelData?.data?.EarlyUnstakeFees && (
                  <Index.Box className="unstake-lock-period-box">
                    <Index.Typography className="unstake-lock-period-text">
                      Early Unstake Fee
                    </Index.Typography>
                    <Index.Typography className="unstake-lock-period-day">
                      {" "}
                      {unstakeModelData?.data?.EarlyUnstakeFees}%
                    </Index.Typography>
                  </Index.Box>
                )}

                {unstakeModelData?.data?.totalDaysEarlyStake && (
                  <Index.Box className="unstake-lock-period-box">
                    <Index.Typography className="unstake-lock-period-text">
                      Early Unstake Fee(token)
                    </Index.Typography>
                    <Index.Typography className="unstake-lock-period-day">
                      {(unstakeModelData?.data?.totalDaysEarlyStake).toFixed(2)}
                    </Index.Typography>
                  </Index.Box>
                )}

                {unstakeModelData && (
                  <Index.Box className="unstake-lock-period-box">
                    <Index.Typography className="unstake-lock-period-text">
                      Reward Tokens
                    </Index.Typography>
                    <Index.Typography className="unstake-lock-period-day">
                      {unstakeModelData?.rewardToken
                        ? unstakeModelData?.rewardToken.toFixed(2)
                        : unstakeModelData?.data?.rewardTokenRemining
                        ? unstakeModelData?.data?.rewardTokenRemining.toFixed(2)
                        : 0}{" "}
                    </Index.Typography>
                  </Index.Box>
                )}

                {unstakeModelData && (
                  <Index.Box className="unstake-lock-period-box">
                    <Index.Typography className="unstake-lock-period-text">
                      Final Tokens Received
                    </Index.Typography>
                    <Index.Typography className="unstake-lock-period-day">
                      {unstakeModelData?.finalToken
                        ? unstakeModelData?.finalToken.toFixed(2)
                        : unstakeModelData?.data?.finalToken
                        ? unstakeModelData?.data?.finalToken.toFixed(2)
                        : 0}{" "}
                    </Index.Typography>
                  </Index.Box>
                )}

                <Index.Box className="delete-modal-btn-flex">
                  <Index.Button
                    className="modal-cancel-btn modal-btn"
                    onClick={() => {
                      handleCloseUnstakeModel();
                      setUnstakeModelData("");
                      setUnstakeAmount("");
                    }}
                  >
                    Cancel
                  </Index.Button>
                  <Index.Button
                    className="modal-delete-btn modal-btn"
                    onClick={() => {
                      Unstake(stakingIdData, unstakeAmount, "true");
                      handleCloseUnstakeModel();
                      setUnstakeModelData("");
                    }}
                  >
                    Confirm
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            )}
          </Index.Modal>
        </Index.Box>
      </Index.Box>

      <PagesIndex.GoToTop />
    </>
  );
}

export default StackDetail;
